<script setup lang="ts">
import { computed } from 'vue'

import type { ComputedRef, PropType } from 'vue'
import type {
  MediaFieldPropertiesBreakpointInterface,
  VoixMediaFieldInterface,
  currentMediaObjectInterface,
} from '@voix/types'
import { useImage } from '#imports'

const props = defineProps({
  renderModel: {
    type: String,
    default: null,
  },
  currentMedia: {
    type: [Object, String] as PropType<VoixMediaFieldInterface | MediaFieldPropertiesBreakpointInterface | string>,
    default: null,
  },
  currentBreakpointName: {
    type: String,
    default: null,
  },
  currentMediaBreakpointMedia: {
    type: String,
    default: null,
  },
  currentMediaBreakpointConfig: {
    type: String,
    required: false,
    default: null,
  },
})

const nuxtImage = useImage()

// Here for type safety if media is passed through as a string
const currentMediaString = computed(() => {
  if (typeof props.currentMedia === 'object')
    return props.currentMedia.url

  return props.currentMedia as string
})

// Here for type safety if media is passed through as an object (Media or Breakpoint)
const currentMediaObject = computed(() => {
  if (typeof props.currentMedia === 'object')
    return props.currentMedia as currentMediaObjectInterface

  return null
})

const backgroundClasses = computed(() => {
  const classes = []

  if (currentMediaObject.value && (props.renderModel === 'Field' || props.renderModel === 'Breakpoint')) {
    if (currentMediaObject.value.position)
      classes.push(`bg-${currentMediaObject.value.position}`)
    if (currentMediaObject.value.fit) {
      // Tailwinds background 'none' is 'auto' which is the closest thing to none
      const fit = currentMediaObject.value.fit === 'none' ? 'auto' : props.currentMedia.modifiers.fit
      classes.push(`bg-${fit}`)
    }
  }

  return classes
})

const nuxtImageUrl: ComputedRef<string> = computed(() => {
  if (typeof props.currentMedia === 'object') {
    const finalModifiers = { ...props.currentMedia.modifiers }

    if (!finalModifiers.width && props.currentMedia.width)
      finalModifiers.width = props.currentMedia.width

    // TODO: It appears that if I pass a empty modifier object that the image will just not load, we need to add width and height here
    return nuxtImage(props.currentMedia.url, finalModifiers, { provider: props.currentMedia.provider })
  }

  return ''
})
</script>

<template>
  <div />
  <template v-if="renderModel === 'String'">
    <slot :image="currentMedia" :background-classes="backgroundClasses" role="img" :aria-label="props.currentMedia.title" />
  </template>

  <template v-if="renderModel === 'Field' || renderModel === 'Breakpoint'">
    <slot :image="nuxtImageUrl" :background-classes="backgroundClasses" role="img" :aria-label="props.currentMedia.title" />
  </template>
</template>
