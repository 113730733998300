<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'

import { useBreakpointStore } from '@voix/store/breakpointStore'
import type {
  BreakpointInterface,
  MediaFieldBreakpointConfigInterface,
  MediaFieldConfigInterface,
  MediaFieldPropertiesBreakpointInterface,
  VoixField,
  VoixMediaFieldBreakpointInterface,
  VoixMediaFieldInterface,
  currentMediaObjectInterface,
} from '@voix/types'

import VoixBackgroundImage from '@voix/components/frontend/media-formats/VoixBackgroundImage.vue'

import {
  getApplicableBreakpoints,
  getCurrentBreakpoint,
} from '@voix/composables/useBreakpoints'

import type { ComputedRef, PropType } from 'vue'
import { useImage } from '#imports'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  background: {
    type: Boolean,
    default: false,
  },
  field: {
    type: [Object, String] as PropType<VoixField | VoixMediaFieldInterface | MediaFieldPropertiesBreakpointInterface | string | VoixMediaFieldBreakpointInterface>,
    default: null,
  },
  breakpoint: {
    type: String,
    default: null,
  },
  loading: {
    type: String as PropType<'lazy' | 'eager'>,
    default: 'lazy',
  },
  type: {
    type: String as PropType<'image' | 'video'>,
    default: 'image',
  },
  fpo: {
    type: Boolean,
    default: false,
  },
  debug: {
    type: Boolean,
    default: false,
  },
})

const windowWidth
  = typeof window !== 'undefined' ? ref(window.innerWidth) : ref(1200)

function updateInnerWidth(event: Event) {
  const theWindow: Window = event.target as Window
  windowWidth.value = theWindow.innerWidth
}

onMounted(() => {
  if (typeof window !== 'undefined')
    window.addEventListener('resize', updateInnerWidth)
})

onUnmounted(() => {
  if (typeof window !== 'undefined')
    window.removeEventListener('resize', updateInnerWidth)
})

const currentBreakpoint: ComputedRef<BreakpointInterface> = computed(() => {
  return getCurrentBreakpoint(windowWidth.value)
})

const randomString = computed(() => {
  return Math.random().toString(36).substring(2, 15)
})

// Determine what the final model should be. This can either be:
// - String: A string
// - Field: Array of Breakpoints with Config
// - Breakpoint: A single breakpoint
const renderModel = computed(() => {
  // If the user passed in a string or the default value on a field is being used
  // because no media has been selected
  if (props.field && (typeof props.field === 'string' || typeof props.field.value === 'string'))
    return 'String'

  // If the user passed in a single breakpoint to be requested
  if (typeof props.breakpoint === 'string')
    return 'Breakpoint'

  // A regular field was passed
  if (typeof props.field === 'object')
    return 'Field'

  return undefined
})

// This is the actual breakpoint for the image that should be returned based on
// what has been set in the CMS bottom up. Meaning, if we're trying to load the 'lg'
// breakpoint media and only the default is available, we will return the default.
const currentMediaBreakpointMedia = computed(() => {
  if (renderModel.value === 'Field') {
    const mediaField = (props.field as VoixMediaFieldInterface).value
    const cb = mediaField.find(
      b => b.breakpoint === currentBreakpoint.value.name,
    )

    if (cb)
      return currentBreakpoint.value.name

    const applicableBreakpoints = getApplicableBreakpoints(
      windowWidth.value,
    ).reverse()

    for (const applicableBreakpoint of applicableBreakpoints) {
      const applicableBreakpointMedia = mediaField.find(
        b => b.breakpoint === applicableBreakpoint.name,
      )
      if (applicableBreakpointMedia)
        return applicableBreakpointMedia.breakpoint
    }
  }

  return 'default'
})

const currentMediaBreakpointConfig = computed(() => {
  if (renderModel.value === 'Field') {
    const mediaConfig = (props.field as VoixMediaFieldInterface).config?.breakpoints
    const applicableBreakpoints = getApplicableBreakpoints(
      windowWidth.value,
    ).reverse()

    for (const applicableBreakpoint of applicableBreakpoints) {
      const applicableBreakpointMedia = Object.keys(mediaConfig).find(
        b => b === applicableBreakpoint.name,
      )
      if (applicableBreakpointMedia)
        return applicableBreakpointMedia
    }
  }

  return undefined
})

const mediaObjectIsWholeField = computed(() => {
  const mediaProp = props.field as VoixMediaFieldInterface
  if (Array.isArray(mediaProp.value))
    return true

  return false
})

// Current Media is the thing that puts it all together
const currentMedia: ComputedRef<currentMediaObjectInterface | string> = computed(() => {
  // Passed through as string on media
  if (renderModel.value === 'String') {
    if (typeof props.field === 'string')
      return props.field as string
    else if (typeof props.field.value === 'string')
      return props.field.value as string
  }

  // Requested a specific breakpoint
  if (renderModel.value === 'Breakpoint') {
    let mediaField: null | VoixMediaFieldBreakpointInterface | VoixMediaFieldInterface = null
    let foundMedia: null | currentMediaObjectInterface = null

    // Passed through media field
    if (mediaObjectIsWholeField.value) {
      mediaField = props.field as VoixMediaFieldInterface

      // If the media field is not an array, it's the default value
      if (!Array.isArray(mediaField.value))
        return mediaField.value

      foundMedia = Object.assign({ type: props.type, modifiers: {} }, mediaField.value.find(
        (b: MediaFieldPropertiesBreakpointInterface) => b.breakpoint === currentMediaBreakpointMedia.value,
      )) as currentMediaObjectInterface
    }

    // Passed through single breakpoint
    else {
      mediaField = props.field as VoixMediaFieldBreakpointInterface
      foundMedia = Object.assign({ type: props.type, modifiers: {} }, mediaField.value) as currentMediaObjectInterface
    }

    if (foundMedia && foundMedia.url && mediaField) {
      const config = mediaField.config as any

      if (typeof foundMedia.modifiers === 'string')
        foundMedia.modifiers = JSON.parse(foundMedia.modifiers)

      if (config) {
        if (config.width)
          foundMedia.modifiers.width = config.width

        if (config.height)
          foundMedia.modifiers.height = config.height

        if (config.position && !foundMedia.modifiers.position)
          foundMedia.modifiers.position = config.position

        if (config.fit && !foundMedia.modifiers.fit)
          foundMedia.modifiers.fit = config.fit
      }

      return foundMedia
    }
  }

  // Passed through as an array of breakpoints on media
  if (renderModel.value === 'Field') {
    const mediaField = props.field as VoixMediaFieldInterface

    if (!mediaField?.value)
      return ''

    const foundMedia = Object.assign({ type: props.type, modifiers: {} }, mediaField.value.find(
      (b: MediaFieldPropertiesBreakpointInterface) => b.breakpoint === currentMediaBreakpointMedia.value,
    )) as currentMediaObjectInterface

    if (foundMedia && foundMedia.url) {
      const config = mediaField.config as any

      if (typeof foundMedia.modifiers === 'string')
        foundMedia.modifiers = JSON.parse(foundMedia.modifiers)

      if (config && currentMediaBreakpointConfig.value && config.breakpoints[currentMediaBreakpointConfig.value]) {
        if (config.breakpoints[currentMediaBreakpointConfig.value].height)
          foundMedia.modifiers.width = config.breakpoints[currentMediaBreakpointConfig.value].width

        if (config.breakpoints[currentMediaBreakpointConfig.value].height)
          foundMedia.modifiers.height = config.breakpoints[currentMediaBreakpointConfig.value].height

        if (config.position && !foundMedia.modifiers.position)
          foundMedia.modifiers.position = config.position
      }

      return foundMedia
    }
  }

  if (props.debug === true)
    console.warn('Unable to find media', props.field)

  return ''
})

// Here for type safety if media is passed through as a string
const currentMediaString = computed(() => {
  if (typeof currentMedia.value === 'object')
    return currentMedia.value.url

  return currentMedia.value as string
})

// Here for type safety if media is passed through as an object (Media or Breakpoint)
const currentMediaObject = computed(() => {
  if (typeof currentMedia.value === 'object')
    return currentMedia.value as currentMediaObjectInterface

  return null
})

// Determine what the final render format should be for the current media being displayed. This can either be:
// - Image: An image
// - Video: A video
const renderFormat = computed(() => {
  const media = currentMedia.value as currentMediaObjectInterface
  if (media && media.type && media?.type === 'video')
    return 'Video'

  return 'Image'
})

const nuxtImage = useImage()

const objectClasses = computed(() => {
  const classes = []

  if (typeof currentMedia.value === 'object' && currentMedia.value.modifiers) {
    if (currentMedia.value.modifiers.position)
      classes.push(`object-${currentMedia.value.modifiers.position}`)
    if (currentMedia.value.modifiers.fit)
      classes.push(`object-${currentMedia.value.modifiers.fit}`)
  }

  return classes
})

function generateNuxtImageUrl(mediaBreakpoint: MediaFieldPropertiesBreakpointInterface, breakpointConfig: MediaFieldBreakpointConfigInterface) {
  if (typeof mediaBreakpoint === 'object') {
    // If the developer has passed in a width or height we will use that
    const mediaField = props.field as VoixMediaFieldInterface
    const config = mediaField.config as any
    const sliceSizing: { width?: number, height?: number } = {}

    // If an explicit breakpoint is set, we will use that
    if (props.breakpoint) {
      if (breakpointConfig.width)
        sliceSizing.width = breakpointConfig.width
      if (breakpointConfig.height)
        sliceSizing.height = breakpointConfig.height
    }
    // Else use the best breakpoint for the current viewport
    else if (currentMediaBreakpointConfig.value) {
      if (config.breakpoints[currentMediaBreakpointConfig.value].width)
        sliceSizing.width = config.breakpoints[currentMediaBreakpointConfig.value].width
      if (config.breakpoints[currentMediaBreakpointConfig.value].height)
        sliceSizing.height = config.breakpoints[currentMediaBreakpointConfig.value].height
    }

    const finalModifiers: any = Object.assign(sliceSizing, currentMedia.value.modifiers, { fit: 'cover' })

    return nuxtImage(mediaBreakpoint.url, finalModifiers, { provider: mediaBreakpoint.provider })
  }

  return ''
}

function generateMediaSrcSetUrl(mediaBreakpoint: MediaFieldPropertiesBreakpointInterface, breakpointConfig: MediaFieldBreakpointConfigInterface): string {
  const mediaBreakpointUrl = generateNuxtImageUrl(mediaBreakpoint, breakpointConfig)
  const modifiers = Object.assign({}, JSON.parse(mediaBreakpoint.modifiers) || {}) as any

  modifiers.width = `${breakpointConfig.width}`

  return `${mediaBreakpointUrl} ${modifiers.width}w`
}

const srcset: ComputedRef<string> = computed(() => {
  let srcset: Array<string> = []

  // If we are rendering a single breakpoint
  if (renderModel.value === 'Breakpoint') {
    // At this point we know we will have a single image in our srcset. We don't know if
    // the developer has passed us the entire media field or just a single breakpoint from that field.
    // We need this capability in a few places including the Media Manager for one off images that
    // aren't technically fields yet. So, we set currentMediaField to null and set it after checking
    // if we have the whole field or just a single breakpoint.
    let currentMediaField: null | VoixMediaFieldInterface | VoixMediaFieldBreakpointInterface = null

    // Passed the whole field through
    if (mediaObjectIsWholeField.value) {
      currentMediaField = Object.assign({}, props.field) as VoixMediaFieldInterface
      const config = currentMediaField.config as MediaFieldConfigInterface
      const selectedBreakpoint = currentMediaField.value.find(
        (b: MediaFieldPropertiesBreakpointInterface) => b.breakpoint === props.breakpoint,
      )

      // In this scenario the breakpoint requested doesn't exist. This could be because
      // it's not even setup in the configuration (probably a developer error) or because
      // the CMS doesn't have a value for that breakpoint.
      // TODO: A possible feature in the future might be to allow the developer to pass a
      // "flexible" option that will allow the component to look for the next available breakpoint
      // if the one requested doesn't exist. The problem with this is that they were explicit
      // in their breakpoint and I'm not sure this is necessary.
      if (!selectedBreakpoint)
        return ''

      if (config.breakpoints && config.breakpoints[props.breakpoint]) {
        const image = generateMediaSrcSetUrl(selectedBreakpoint, config.breakpoints[props.breakpoint])
        if (image)
          srcset = srcset.concat(image)
      }
    }

    // Passed a single breakpoint through
    else {
      currentMediaField = Object.assign({}, props.field) as VoixMediaFieldBreakpointInterface
      const config = currentMediaField.config as MediaFieldConfigInterface
      if (config.breakpoints && config.breakpoints[props.breakpoint]) {
        const image = generateMediaSrcSetUrl(currentMediaField.value, config.breakpoints[props.breakpoint])
        if (image)
          srcset = srcset.concat(image)
      }
    }
  }

  // If we are rendering the whole field we will loop through the array and build up
  // the srcset with all the various options. This is why we couldn't use NuxtImage
  // directly. It doesn't support multiple (different) images in the srcset.
  if (renderModel.value === 'Field') {
    const currentMediaField = Object.assign({}, props.field) as VoixMediaFieldInterface
    const config = currentMediaField.config as MediaFieldConfigInterface
    currentMediaField.value.forEach((media: any) => {
      // TODO: We may want to remove `&& media.breakpoint === currentMediaBreakpointMedia.value` to add all
      // possible srcsets to the image. This would allow the browser to choose the best one for the current
      // viewport. We don't do this currently because the browser "holds on" the the first one rendered.
      if (config.breakpoints && config.breakpoints[currentMediaBreakpointMedia.value] && media.breakpoint === currentMediaBreakpointMedia.value) {
        const image = generateMediaSrcSetUrl(media, config.breakpoints[media.breakpoint])
        if (image)
          srcset = srcset.concat(image)
      }
    })
  }

  // Check to see if we need to use a default image (and if there is a default image)

  if (srcset.length === 0 && currentMediaObject.value && currentMediaObject.value.url)
    srcset = srcset.concat(currentMediaObject.value.url)

  // convert the array to a string for the srcset attribute and make it
  // comma separated then return it
  return srcset.join(', ')
})
</script>

<template>
  <template v-if="!background">
    <!-- Non-Background Image  -->
    <template v-if="renderFormat === 'Image'">
      <template v-if="renderModel === 'String'">
        <NuxtImg
          :src="currentMediaString"
          v-bind="$attrs"
          :modifiers="{ q: 90, f: 'webp', fit: 'cover' }"
          :loading="loading"
        />
      </template>
      <template v-else-if="renderModel === 'Field' && currentMediaObject">
        <!--
           When the image comes in as the entire media object this is how we serve them.
           We build a srcset attribute and build up our own image tag
          -->
        <img
          v-if="srcset"
          :srcset="srcset"
          :alt="currentMediaObject.title"
          v-bind="$attrs"
          :loading="loading"
          :class="objectClasses"
        >
        <div
          v-else-if="fpo" class="bg-zinc-200 flex items-end font-voix-sans font-bold p-4 overflow-hidden text-3xl"
          v-bind="$attrs" :class="objectClasses"
          alt="For Placement Only - Placeholder"
        >
          <div>FPO</div>
          <div />
        </div>
      </template>
      <template v-else-if="renderModel === 'Breakpoint' && currentMediaObject">
        <!-- When passing a single breakpoint in we handle it like so -->

        <img
          v-if="srcset"
          :srcset="srcset"
          :alt="currentMediaObject.title"
          v-bind="$attrs"
          :loading="loading"
          :class="objectClasses"
        >
        <div
          v-else-if="fpo" class="bg-zinc-200 flex items-end font-voix-sans font-bold p-4 overflow-hidden text-3xl"
          v-bind="$attrs"
          :class="objectClasses"
          alt="For Placement Only - Placeholder"
        >
          <div>FPO</div>
          <div />
        </div>
      </template>

      <!-- Non-Background Video -->
    </template>
    <template v-if="renderFormat === 'Video'">
      <div>
        <video
          :key="currentMedia.breakpoint"
          :src="currentMedia.url"
          :width="currentMedia.width"
          :height="currentMedia.height"
          v-bind="$attrs"
          autoplay muted loop
          :class="objectClasses"
        />
      </div>
    </template>
  </template>

  <!-- Background Images -->
  <template v-if="background">
    <VoixBackgroundImage
      v-slot="slotProps" :render-model="renderModel" :current-media="currentMedia"
      :current-breakpoint-name="currentBreakpoint.name" :current-media-breakpoint-media="currentMediaBreakpointMedia"
      :current-media-breakpoint-config="currentMediaBreakpointConfig"
    >
      <slot :image="slotProps.image" :background-classes="slotProps.backgroundClasses" />
    </VoixBackgroundImage>
  </template>

  <!-- TODO: Need better image debugging - hey there dev! -->
  <pre v-if="debug === true" class="mt-[25px] bg-white p-8 rounded-sm whitespace-pre-line break-words">
    <ClientOnly>
      <strong>Render Model:</strong> {{ renderModel }}
      <strong>Current Window Breakpoint:</strong> {{ currentBreakpoint.name }}
      <strong>Current Breakpoint Media:</strong> {{ currentMediaBreakpointMedia }}
      <strong>CurrentBreakpoint Config Key:</strong> {{ currentMediaBreakpointConfig }}
      <strong>CurrentBreakpoint Config:</strong> {{ currentMediaBreakpointConfig }}
      <strong>CurrentMedia:</strong> {{ currentMedia }}
      <strong>Srcset:</strong> {{ srcset }}
      <strong>Media:</strong> <pre>{{ media }}</pre>
</ClientOnly>
</pre>
</template>
